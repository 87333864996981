import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({
  pageTitle,
  pageDescription,
  pagePath,
  pageImg,
  pageImgw,
  pageImgh,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang
          title
          description
          siteUrl
          locale
          fbappid
        }
      }
    }
  `);

  const title = pageTitle
    ? `${pageTitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title;
  const description = pageDescription || data.site.siteMetadata.description;
  const url = pagePath
    ? `${data.site.siteMetadata.siteUrl}${pagePath}`
    : data.site.siteMetadata.siteUrl;
  const imgUrl = pageImg
    ? `${data.site.siteMetadata.siteUrl}${pageImg}`
    : `${data.site.siteMetadata.siteUrl}/thumb.jpg`;
  const imgw = pageImgw || 1280;
  const imgh = pageImgh || 640;

  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta property="og:site_name" content={data.site.siteMetadata.title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:width" content={imgw} />
      <meta property="og:image:height" content={imgh} />
      <meta property="fb:app_id" content={data.site.siteMetadata.fbappid} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default Seo;
