import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import { container } from "./layout.module.css";

const Layout = ({ children }) => {
  return (
    <div className={container}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
